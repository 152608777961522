import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const ResourceCard = makeShortcode("ResourceCard");
const MdxIcon = makeShortcode("MdxIcon");
const Caption = makeShortcode("Caption");
const DoDontExample = makeShortcode("DoDontExample");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3 {...{
      "id": "the-right-panel-is-part-of-the-carbon-ui-shell-a-shell-is-a-collection-of-components-shared-by-all-products-within-a-platform-it-provides-a-common-set-of-interaction-patterns-that-persist-between-and-across-products"
    }}>{`The right panel is part of the Carbon UI shell. A shell is a collection of components shared by all products within a platform. It provides a common set of interaction patterns that persist between and across products.`}</h3>
    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">General guidance</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Anatomy</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Behavior</AnchorLink>
    </AnchorLinks>
    <h2 {...{
      "id": "resources"
    }}>{`Resources`}</h2>
    <Row className="resource-card-group" mdxType="Row">
  <Column colLg={4} colMd={4} noGutterSm mdxType="Column">
    <ResourceCard subTitle="UI Shell template" href="https://sketch.cloud/s/EjVmA" mdxType="ResourceCard">
      <MdxIcon name="sketch" mdxType="MdxIcon" />
    </ResourceCard>
  </Column>
    </Row>
    <h2 {...{
      "id": "general-guidance"
    }}>{`General guidance`}</h2>
    <p>{`The UI shell is made up of three components: The `}<a parentName="p" {...{
        "href": "/components/UI-shell-header/usage"
      }}>{`header`}</a>{`, the `}<a parentName="p" {...{
        "href": "/components/UI-shell-right-panel/usage"
      }}>{`left panel`}</a>{`, and the right panel. All three can be used independently, but the components were designed to work together.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Shell UI component`}</th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Header`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The highest level of navigation. The header can be used on its own for simple products or be used to trigger the left and right panels.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Left panel`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An optional panel that is used for a product’s navigation.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Right panel`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An optional panel that shows additional system level actions or content associated with a system icon in the header.`}</td>
        </tr>
      </tbody>
    </table>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "83.42391304347827%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAYAAADdRIy+AAAACXBIWXMAAAsSAAALEgHS3X78AAACXklEQVQ4y5WUS2gTURSGbyaxRauJgTSTGScSLaWbklZUfIA7cavgxlAXLqwuXLsQSSQQSRdKFoK4sBjHEBCCWYhgKb4KCiKKpYiKIFiKIVNjYkLzmslc/5mmIbdJS3rg59xz5/Ddc+5jCNnEPo9cZOL5kUnSswmCYPH5fBw853a7OZ7nOZd7kPPyAndp71HrWWnc2je42yLyHvObISPPyPd6vZwkSb0vRind1ksewEw8BB2A/G0aa/pD0Pi6Ob/H4/GLojiGzvyA7WBowWDwZSwWo9FotBYOh7VQKNTSjbYx8rRIJKIFAgHN5XKpADbQrorWjzHAarX6hq5ag25iuq6bPp1OUwApqqTYT8MfZ4D5fP6Vqqq0Xq+rtVpNLxQKXZXL5XTk6YlEQsehNAwY1ACQrVBRlNfNIjQDXCwWuwpQs8pkMkkB1JvVNTpaRnILCGsBSqUSIwNoGCqkTqdTb7bcWSH2cEtAWZap3W7fGFipVDYEto+3AnzRBFaxhyoAXYXDU5GjAqgCWAdIA7DereUPtAdbuzapVIo6HA6Ke2heHegEA/z7r3BtpVh6XC6XH6A1GacuK9msrPxZfphd+v3IHGMuk8nIyJHvT0/LAMYlQZTdPB/fI4jDJmjGdaYFnSJkYCch1vVv9DIhfXCWjjd+erGfHk4OrMXnpYOEfBu9YgbLV+O2HyevO5cmbm9HR8YPgYMsdO67tRh7alu5N2OjudrqHDT78Z3l2dC5XbPDE45J6QhbxKd9F5j4690nZKGikIXnc8z8Fyw0//Y9+bn4i9yZusV8u7n/lOn/A+zvT3LmMoi9AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "UI shell components.",
            "title": "UI shell components.",
            "src": "/static/be299d265c24f9028bcc0646d8bfd634/fb070/shell-usage-1.png",
            "srcSet": ["/static/be299d265c24f9028bcc0646d8bfd634/d6747/shell-usage-1.png 288w", "/static/be299d265c24f9028bcc0646d8bfd634/09548/shell-usage-1.png 576w", "/static/be299d265c24f9028bcc0646d8bfd634/fb070/shell-usage-1.png 1152w", "/static/be299d265c24f9028bcc0646d8bfd634/c3e47/shell-usage-1.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <Caption mdxType="Caption">UI shell components</Caption>
      </Column>
    </Row>
    <h2 {...{
      "id": "anatomy"
    }}>{`Anatomy`}</h2>
    <p>{`The right panel is invoked by icons on the right side of the header, and remains anchored to that icon. Right panels have a consistent width, span the full height of the viewport, and are flush to the right edge of the viewport.`}</p>
    <p>{`Note that the switcher also lives in a right panel.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "57.85714285714286%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsSAAALEgHS3X78AAABzElEQVQoz41TTUtCQRQdv9q0CPHjPZ/65mnqS4n2RVAQRYuEQFzVoqJ2EbVqF5SkRrjJaBPU0pVr6Re0ctEvaWWUEtO5z6tlYDRwuI7nzJkzc+eJZrPpFkIQPL/gEjyUUsKD8ZfGNE3xr5HP511/8T42fc2e9P+o1+tnoVDoLhqNVpLJ5FUikagCtVgsNjdY1Gq19rLZ7H0gEChLKa+kZZVNKeumJdcdgb+ve7EPhGg0GkUYPhmGoWDqAGYqEolsDQzb7fZKOp1+iMfjCpspE7wVN5WMRG+JL1urk8/27vdJsHhH13UVDoffgHdAYV4gDiYTVLFpzrbtHoy7uqZ3SG/qRpW4x+TmVNvedw8NQR6TQNO0HvBJv4EicUjuo4rUS6lUShGg+XD0ulZxDLxOU/tHZsMjTtgDPrFgaIgr8HFdzGQyigDugzd1Ek6HDO/1zIb4mXCs4SAhGeK4TkIyxDVRdRIGg0EvmjZiePjzDtmw8MtwAa+gi6Z0wXdYczkwzOVyI4an1GU+huLdt5mb4MYtkwk3TLH+hjg8p9GEEM+DvAbOgQs6ChbMckIPVwldCVyJdTXM1/gFuIcJMRn7JSCNi+vYL8Xv94+s/wKIy4+3IoE3sAAAAABJRU5ErkJggg==')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Header panel and switcher.",
        "title": "Header panel and switcher.",
        "src": "/static/adc721baba0e93cdcb613c5d05255d9c/fb070/right-panel-usage-1.png",
        "srcSet": ["/static/adc721baba0e93cdcb613c5d05255d9c/d6747/right-panel-usage-1.png 288w", "/static/adc721baba0e93cdcb613c5d05255d9c/09548/right-panel-usage-1.png 576w", "/static/adc721baba0e93cdcb613c5d05255d9c/fb070/right-panel-usage-1.png 1152w", "/static/adc721baba0e93cdcb613c5d05255d9c/fb104/right-panel-usage-1.png 1728w", "/static/adc721baba0e93cdcb613c5d05255d9c/8fefe/right-panel-usage-1.png 2240w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <Caption mdxType="Caption">
  The right panel configured as an empty header panel (left) and a switcher
  (right).
    </Caption>
    <h4 {...{
      "id": "switcher-item"
    }}>{`Switcher item`}</h4>
    <p>{`A switcher item is anything that changes what product, offering, or property occupies the UI shell. Consider moments in a product when you switch from a calendar to a mailbox, from Kubernetes to Catalog. These items belong in the switcher.`}</p>
    <h4 {...{
      "id": "switcher-divider"
    }}>{`Switcher divider`}</h4>
    <p>{`A switcher divider groups similar switcher items. You can use a divider to set apart a parent domain, group child domains similar in hierarchy to the parent, and set apart additional resources. The divider should not be used to separate every switcher item.`}</p>
    <h3 {...{
      "id": "switcher"
    }}>{`Switcher`}</h3>
    <p>{`The far right header icon is reserved for the switcher icon. The switcher icon and the switcher panel should only be used together.`}</p>
    <Row mdxType="Row">
      <Column colMd={4} colLg={6} mdxType="Column">
  <DoDontExample type="do" caption="Positioned the switcher to the far right." mdxType="DoDontExample">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsSAAALEgHS3X78AAABv0lEQVQ4y7WUS04CQRCGaXqASZAVCe/3m8DSCxgPINFLSGTJjisY9x7AsNDjKMrGFWHB4wCK3eXfnR7CgkdDQiV/ajKp+qaquqZ9vnPZYrHQnoh83W6XBQIBlkqlWDweXysWi+l36XRax+J5P1TBbI0xtj9guVx6UN7r9UKO47i5XC6sDK/DnHPtXde9iEajoYNfnM/n3ADv0fJvMBicoM2vTCYzLhaL43w+P65UKqNSqTQtFApPKrZer/N9FTIDvOz3+w+RSOQZEEIyKY95EWanlUwmX80M+aEZrgeDSq4gKpfLK0BFIpEQOJQfeIJeVAw62A2czWbaDwYDR3lUcocWCRIAS1QlAVjBK+BQxcDvBkoptce8/Mpns9lOo9GgZrMpqtWqRLJEhStT4fBghZ5tAlutFrXbbQGwrvAkIEAaiNPtmBkKfERXeFTLnuFE10C0SrVaTQB6/Ay3AQEj7JoGnjxDBPvNjt0ggRQE+sOzgOzXZgPIDfBWJSvopkzLb9ZAJPjNHl4j8RtJH9AI+oTeoQliHq1niHlpj4XmOOXwNiEmZP4mu6vJJhCrZAfD6WqPq0u1zbbJi/Eu2rPaP68Krph9k17GAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Example of the switcher icon being used correctly.",
              "title": "Example of the switcher icon being used correctly.",
              "src": "/static/76e06dc30784b26ec5dfab4070ec4517/fb070/right-panel-usage-2.png",
              "srcSet": ["/static/76e06dc30784b26ec5dfab4070ec4517/d6747/right-panel-usage-2.png 288w", "/static/76e06dc30784b26ec5dfab4070ec4517/09548/right-panel-usage-2.png 576w", "/static/76e06dc30784b26ec5dfab4070ec4517/fb070/right-panel-usage-2.png 1152w", "/static/76e06dc30784b26ec5dfab4070ec4517/c3e47/right-panel-usage-2.png 1472w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </DoDontExample>
      </Column>
      <Column colMd={4} colLg={6} mdxType="Column">
  <DoDontExample caption="Do not position other icons to the right of the switcher." mdxType="DoDontExample">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsSAAALEgHS3X78AAABxUlEQVQ4y7WUSU4CQRSGu6gGDC0bFszzTCBh4wWMB5DoJSSyZMcVjHsPQFzocRRl44q4YDgAYlX510s1cQHSEKnkz6tOv/fVG6rbso615vM5WaWU1e12md/vZ8lkkkWjURaPx2kfi8XoGZZ8Xbt1adi/rcVi4UJ5r9cL2rZ9ks1mnVAo5HDOnUgk4iBrBy6nUHAncDabcQO8QclfgUBggpLe0+n0uFgsjgEf5/P5UalU+iyXy/fat91u878yZAZ41u/3b8Ph8EMul1OAkBKJBCmTySgc8qR98Z7v6iFz98jqHFLIaAWgwGAEBrJE1goaah88bwdOp1Oyg8HA1jaVSl2jNAUJgCWykwCtdJaAP2of2O1AKSXZQqHg0xalder1umo0GqJSqUgES2S0gl0DcQDfOZzfwGazqVqtlgCYMjwICBAB0fiO6aHAIZThXiW7C5NbA1GqqlarAtD9e7gJCJiq1WoEPLiHcCYgvt1LfUU0BPrGXkBLAxzuA+QGeKWDzb1by5T87BmIAJ+5hxcI/EDQKzSC3qAXaAKfO889RL/I4kJzTNnZJPgEzdfk7c/jxRFXyRsM0yWLv4sum22S64O9dfT1A6LCrpso1aLsAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "The switcher icon is not positioned to the far right.",
              "title": "The switcher icon is not positioned to the far right.",
              "src": "/static/865d9b816bb82b5db913e7ce19ddb08d/fb070/right-panel-usage-3.png",
              "srcSet": ["/static/865d9b816bb82b5db913e7ce19ddb08d/d6747/right-panel-usage-3.png 288w", "/static/865d9b816bb82b5db913e7ce19ddb08d/09548/right-panel-usage-3.png 576w", "/static/865d9b816bb82b5db913e7ce19ddb08d/fb070/right-panel-usage-3.png 1152w", "/static/865d9b816bb82b5db913e7ce19ddb08d/c3e47/right-panel-usage-3.png 1472w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </DoDontExample>
      </Column>
      <Column colMd={4} colLg={6} mdxType="Column">
  <DoDontExample caption="Do not use another icon for the switcher." mdxType="DoDontExample">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsSAAALEgHS3X78AAABvUlEQVQ4y7WUSU4CQRSGaaoBE2TLPM/TUg5gPIBELyGRJTuuYNx7AOJCj6MoG1fEBcMBEKue/6tUExYMDZFK/vzVnVdfv/equjyeU43ZbKadiDydTsfy+XxWPB63IpHISuFwWL9LJBI6FvPdUIb925jP5w5UdLvdgG3bZ+l0OsgDr4NCCO3QORTYC5xOp8IA71Dyj9/vH6PMz2QyOcrlcqNMJjMqFArDSqXyXa/XHzm21WqJXRlaBnjR6/XuQ6HQEyCUzWaJHf0i9I5SqRT7C8diLvb10HLm+Xz+EiJktQRURqNRiU1ZIGvCfMAxeN4OnEwm2vv9vs2OLG6LxSJBEmAVi8UUYEs4A585Br4dqJTSjn55TTntarVKtVpNlkolhcUKGS3hKyA+IPZuzjqw0WhQs9mUAOsMjwICpIHY3bbpocRHdIYHlewM7OgKiFKpXC5LQA/v4SYgYIRzp4FH9xDBXvOfXvMRYQj0i7mEFgY4OAQoDPCGFzN0XabkV9dALPCac3iFhV9Y9A4NoQ/oDRoj5sF1D9Ev7TjQArsc3CTEBMzf5O7mcROIo+QOht3VjquLy7Y2yYlxLtqTjj/XzK6nSheSIQAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "The switcher not using the switcher icon.",
              "title": "The switcher not using the switcher icon.",
              "src": "/static/e1493ac07ba1f9337e92ef7ee6d88e2b/fb070/right-panel-usage-4.png",
              "srcSet": ["/static/e1493ac07ba1f9337e92ef7ee6d88e2b/d6747/right-panel-usage-4.png 288w", "/static/e1493ac07ba1f9337e92ef7ee6d88e2b/09548/right-panel-usage-4.png 576w", "/static/e1493ac07ba1f9337e92ef7ee6d88e2b/fb070/right-panel-usage-4.png 1152w", "/static/e1493ac07ba1f9337e92ef7ee6d88e2b/c3e47/right-panel-usage-4.png 1472w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </DoDontExample>
      </Column>
    </Row>
    <h2 {...{
      "id": "behavior"
    }}>{`Behavior`}</h2>
    <h4 {...{
      "id": "expansion"
    }}>{`Expansion`}</h4>
    <p>{`Right panels always float over page content, and always remain anchored to their associated icon. You can have multiple right panels, but only only one can be expanded at any time.`}</p>
    <h4 {...{
      "id": "dismissal"
    }}>{`Dismissal`}</h4>
    <p>{`Once expanded, the panel’s associated icon is outlined, with its bottom border flowing into the panel. To dismiss the panel, a user must select an item, or click or tap the header icon.`}</p>
    <h4 {...{
      "id": "selected-state"
    }}>{`Selected state`}</h4>
    <p>{`There is no selected state for right panel items. Even if a user is currently within one of the panel items, the item remains unselected.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      